
.sidebar-widget {
  padding-bottom: 60px;
}

.sidebar-widget h3 {
  margin-bottom: 30px;
  text-transform: uppercase;
}

/* .... SEARCH .... */
.search {
  padding: 25px;
  position: relative;
}

.search input[type="text"] {
  border: 0;
  border-radius: 0;
  width: 100%;
  color: #989898;
  font-style: italic;
  padding: 0 15px 0;
  margin: 0;
  height: 50px;
}

.search .search-icon {
  border: 0 none;
  background: none;
  font-size: 15px;
  height: 50px;
  min-width: 0;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 25px;
  width: 49px;
}

.search .search-icon i {
  color: #525866;
  margin-top: 3px;
  font-size: 20px;
}

/* .... TAGS .... */
.tags {
  list-style: none;
}

.tags li {
  float: left;
  margin: 0 5px 5px 0;
}

.tags li a {
  border: 1px solid #c8c8c8;
  display: block;
  color: #555555;
  font-size: 14px;
  font-family: $default-font-1;
  padding: 11px 21px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tags li a:hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* .... POPULAR POST .... */
.popular-post {
  padding: 19px 0;
}

.popular-post img {
  float: left;
  width: 71px;
  margin: 0 12px 0 0;
}

.popular-post h4, .popular-post h4 a {
  font-weight: 400;
  margin: 0;
  font-size: 18px;
  color: #373737;
  font-family: $default-font-2;
  text-transform: none;
}

.best-seller h4 {
  line-height: 21px;
}

.popular-post p {
  line-height: 14px;
  margin-bottom: 0;
}

.popular-post p strong {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-top: 3px;
  font-family: $default-font-1;
}

.popular-post p.popular-date {
  margin: 0;
  font-size: 14px;
  color: #8d8d8d;
  line-height: 25px;
  font-family: $default-font-1;
}

.blog-review-border {
  border: 1px solid #c8c8c8;
  padding: 5px;
  margin-bottom: 110px;
}

.blog-review {
  border: 1px solid #c8c8c8;
  padding: 25px;
}

.blog-review img {
  float: left;
  border-radius: 100%;
}

.blog-review-content {
  padding: 10px 0 0 175px;
}

.blog-review-content h5 {
  margin-bottom: 10px;
}

.blog-review-content h5 span {
  color: #646464;
  font-size: 16px;
  display: inline-block;
  padding-left: 8px;
}

.categories li {
  list-style: none;
  font-family: $default-font-1;
  font-size: 18px;
  font-weight: 300;
  padding: 7px 0;
}

.categories li a {
  color: #555555;
}

.categories li i {
  float: left;
  margin: 2px 11px 0 0;
  font-size: 20px;
}

.cases-filter-nav {
  background: #f3f5fa;
  padding: 14px;
  text-align: center;
  margin-bottom: 50px;
}

.cases-filter-nav li {
  display: inline-block;
  list-style: none;
  margin: 0 5px;
}

.cases-filter-nav li a {
  font-size: 16px;
  line-height: 15px;
  font-family: $default-font-1;
  color: #000;
  outline: medium none;
  padding: 14px 25px 13px;
  border-radius: 30px;
  text-decoration: none;
  display: block;
}

.cases-filter-nav li a:hover, .cases-filter-nav li a.selected {
  color: #fff;
}

.cases-container li {
  text-align: center;
  width: 50%;
  list-style: none;
}

.cases-container li img {
  width: 100%;
}

.cases-item {
  position: relative;
}

.cases-item img {
  width: 100%;
}

.cases-item figcaption {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  color: #fff;
  font-size: 24px;
  line-height: 25px;
  padding: 20px;
  text-align: center;
  transform: scale(0);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.cases-item figcaption small {
  display: block;
  font-size: 16px;
  font-family: $default-font-1;
}

.cases-item figcaption i {
  display: block;
  font-size: 30px;
  margin-bottom: 2px;
}

.cases-item div {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.cases-item:hover figcaption {
  transform: scale(1);
}

.left-nav {
  margin-bottom: 50px;
}

.left-nav li {
  list-style: none;
}

.left-nav li a {
  display: block;
  padding: 16px 26px 18px 22px;
  font-size: 16px;
  color: #1c1c1c;
  font-family: $default-font-1;
  border-top: 1px solid #dadee8;
  background: #f3f5fa;
  border-left: 5px solid transparent;
}

.left-nav li a i {
  float: right;
  font-size: 24px;
  color: #48494b;
}

.left-nav li:first-child a {
  border: 0;
}

.left-nav li a:hover, .left-nav li a.active {
  background: #fff;
  border-top-color: #fff !important;
}

.help-widget {
  padding: 42px 33px;
  background: url(../images/help-icon.png) no-repeat right bottom;
}

.help-widget h5, .help-widget p {
  color: #fff;
}

.company-presentation-link {
  display: block;
  padding: 18px 30px;
  color: #fff;
  background: #323946;
}

.company-presentation-link i {
  margin-right: 10px;
}

.company-presentation-link:hover {
  color: #fff;
  background: #20252e;
}

aside .testimonial-content {
  padding: 45px 30px 60px;
  background-repeat: no-repeat;
  background-position: 25px 91%;
}
