$theme-colors: (
        green1: #09a223,
        red1: #EC1C24,
        purple1: #3a2c5f,
        grey1: #565a60,
        grey2: #4b4e53,
        grey3: #414146,
		orange1: #F15A29,
		gray1: #4D4D4D,
		blue1: #2B3990
);

$default-theme-color: map_get($theme-colors, blue1);
$default-reverse-color: map_get($theme-colors, red1);

$default-font-1: "Open Sans", sans-serif;
$default-font-2: "Montserrat", sans-serif;