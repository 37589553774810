.shop-list {
  margin: 0 -10px;
  padding: 0 10px;
  width: 102.3%;
}

.shop-list h2 {
  font-size: 32px;
}

.shop-list li {
  display: inline-block;
  padding: 0 10px;
  width: 32.8%;
  vertical-align: top;
}

.product {
  border: 1px solid #dadada;
  display: inline-table;
  width: 100%;
  margin: 0 0 27px;
}

.product-thumb {
  position: relative;
  margin: -1px;
}

.product-thumb img {
  width: 100%;
}

.product-thumb label {
  border-radius: 100%;
  color: #fff;
  display: block;
  height: 59px;
  left: 16px;
  line-height: 61px;
  position: absolute;
  text-align: center;
  top: 16px;
  width: 59px;
}

.product-description {
  padding: 25px 20px 17px;
}

.product-description h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.product-description h3 a {
  color: #000;
}

.price {
  font-size: 20px;
  font-family: $default-font-1;
  font-weight: 300;
}

.price.big {
  font-size: 26px;
  font-weight: 400;
}

.price u {
  color: #797979;
  font-size: 16px;
  text-decoration: line-through;
}

.product-description .double-border {
  margin-bottom: 13px;
}

.product-description .product-cart-btn, .product-description .product-detail-btn {
  color: #222222;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  font-family: $default-font-1;
}

.product-cart-btn i {
  font-size: 26px;
  float: left;
  margin: 3px 3px 0 0;
}

.product-detail-btn i {
  font-size: 24px;
  float: left;
  margin: 5px 9px 0 0;
}

.showing-result {
  margin: 0 0 40px 0;
}

.showing-result p {
  float: left;
  font-size: 20px;
  margin: 8px 0 0;
}

.showing-result .styled-select-small {
  float: right;
}

.top-products {
  border-bottom: 1px solid #dadada;
  padding-bottom: 27px;
  margin-bottom: 28px;
}

.top-products img {
  float: left;
}

.top-products .top-products-detail {
  padding-left: 98px;
}

.top-products .top-products-detail h4, .top-products .top-products-detail p {
  margin: 0;
}

.top-products .top-products-detail h4 {
  font-family: $default-font-1;
  font-size: 20px;
  padding-bottom: 0 !important;
}

.top-products .top-products-detail h4 a {
  color: #000;
}

.top-products .top-products-detail p {
  font-weight: 600;
}

.ratings i {
  display: inline-block;
  font-size: 16px;
}

.ratings.big i {
  font-size: 20px;
}

.ratings.big span {
  font-size: 16px;
  font-family: $default-font-1;
}

.item-counter {
  border: 1px solid #e0e0e0;
  float: left;
  height: 42px;
  margin-right: 17px;
  width: 139px;
  border-radius: 1px;
}

.item-counter span {
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 42px;
}

.item-counter input.items-total {
  background: #ffffff;
  border: 0 none;
  cursor: default;
  display: inline-block;
  height: 39px;
  line-height: 40px;
  margin: 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 49px;
}

.item-counter span.pull-left {
  border-right: 1px solid #e0e0e0;
}

.item-counter span.pull-right {
  border-left: 1px solid #e0e0e0;
}

.product-detail-description h2 {
  margin-bottom: 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

.product-detail-description .ratings {
  margin-bottom: 20px;
}

.product-detail-description p {
  margin-bottom: 20px;
}

.product-detail-slider img {
  border: 0 !important;
  padding: 0 !important;
}

.product-detail-slider .rslides_tabs {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  z-index: 50;
}

.product-detail-slider .rslides_tabs li {
  display: inline-block;
  float: none;
  margin: 0 1.5px;
}

.product-detail-slider .rslides_tabs li a {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #b0b0b0;
  border-radius: 100%;
}

.resp-tab-content .comment-avartar {
  margin-right: 20px;
}

.resp-tab-content .comment-text {
  padding-left: 80px;
}

.resp-tab-content .meta {
  margin: 0;
}

.resp-tab-content .ratings {
  float: right;
}

.resp-tab-content .ratings li {
  float: left;
  margin: 0 3px;
}

.resp-tab-content .comment {
  padding: 0;
  margin: 0;
  border: 0;
}

.resp-tab-content .comment .comment_container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

.resp-tab-content .comment .comment_container:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.resp-tab-content h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

.additional-detail li {
  border-bottom: 1px dotted #ccc;
  font-size: 18px !important;
  padding: 9px 0 3px;
}

.additional-detail li:last-child {
  border-bottom: 0;
}

.additional-detail li label {
  width: 28%;
}

.add-review-form {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

.review_form_thumb {
  padding: 50px 0 20px;
}

.add-review-form .comment-respond input[type="text"],
.add-review-form .comment-respond input[type="email"],
.add-review-form .comment-respond input[type="password"],
.add-review-form .comment-respond input[type="number"],
.add-review-form .form-widget input[type="text"],
.add-review-form .form-widget input[type="email"],
.add-review-form .form-widget input[type="password"],
.add-review-form .form-widget input[type="number"] {
  width: 100%;
  float: none;
  margin-right: 0;
}

.review-form-close {
  background: #000;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  padding: 8px 18px 8px;
  position: absolute;
  right: -100px;
  top: 30px;
  display: block;
}

.review-form-close:hover {
  color: #fff;
}

.your-rating {
  padding: 0 0 10px;
}

.rating {
  display: inline;
  list-style: none;
  padding: 0;
}

.rating li:before {
  font-size: 20px;
  color: #f1f1f1;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  margin: 6px 0 0;
}

.rating > li {
  display: inline-block;
  position: relative;
  width: 22px;
}

.rating > li:hover:before,
.rating > li:hover ~ li:before {
  color: #ffb200;
  cursor: pointer;
}

.shop .sidebar-widget {
  border-bottom: 1px solid #dadada;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.shop .sidebar-widget:last-child {
  border-bottom: 0;
}

.shop .sidebar-widget h4 {
  text-transform: none;
  padding-bottom: 20px;
}

.double-border {
  display: block;
  height: 4px;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
