a {
  color: #2B3990;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  color: #2B3990;
}

.color-default {
  color: #2B3990 !important;
}

.btn-link {
  color: #2B3990;
}

#header .main-nav li a:hover, #header .main-nav li.active a {
  border-color: #2B3990;
}

#header .search-container .search button {
  color: #2B3990;
}

#header .search-container .search input[type="text"]:focus {
  border-color: #2B3990;
}

.no-touch .cd-slider-nav a:hover {
  background-color: #2B3990;
}

.cd-slider-nav .cd-marker {
  background-color: #2B3990;
}

#footer a:hover {
  color: #2B3990;
}

.btn-primary {
  background-color: #2B3990;
}

.owl-dots .owl-dot.active {
  background-color: #2B3990;
}

.counter span {
  color: #2B3990;
}

.service-box, .service-box.two:hover {
  border-color: #2B3990;
}

.service-box.three:after {
  background: #2B3990;
}

.service-box i {
  color: #2B3990;
}

.toggle .toggle-heading a i {
  color: #2B3990;
  border-color: #2B3990;
}

.meet-advisors-content h3 {
  color: #2B3990;
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  background-color: #2B3990;
}

#header .main-nav li.dropdown ul.dropdown-menu li a:hover, #header .main-nav li.dropdown ul.dropdown-menu li.active a {
  background-color: #2B3990;
}

.cd-slider-nav li .slide-number {
  background-color: #2B3990;
}

.cd-slider-nav li.selected .slide-number, .cd-slider-nav li:hover .slide-number {
  color: #2B3990;
}

#header.header-two .header-contact-widget li i {
  color: #2B3990;
}

#header.header-two .header-contact-widget li p a:hover {
  color: #2B3990;
}

.breadcrumbs li a:hover {
  color: #2B3990;
}

.blog-item .blog-content h3 a:hover {
  color: #2B3990;
}

.blog-item .blog-meta li a:hover {
  color: #2B3990;
}

.categories li a:hover {
  color: #2B3990;
}

.tags li a:hover {
  color: #2B3990;
  border-color: #2B3990;
}

.popular-post h4 a:hover {
  color: #2B3990;
}

.search {
  background-color: #2B3990;
}

.search .search-icon:hover i {
  color: #2B3990;
}

.blog-item-classic .blog-content a.btn-link, .blog-item .blog-content a.btn-link {
  border-color: #2B3990;
}

.blog-item-classic .blog-content h3 a:hover {
  color: #2B3990;
}

.news-paggination li a:hover {
  color: #2B3990;
}

.company-history li .year {
  color: #2B3990;
}

.company-history li .history-content:before {
  background-color: #2B3990;
}

.team-member h4 {
  color: #2B3990;
}

.list-bullets li:before {
  background: #2B3990;
}

.left-nav li a:hover, .left-nav li a.active {
  border-color: #2B3990;
}

.help-widget {
  background-color: #2B3990;
}

.product-description h3 a:hover {
  color: #2B3990;
}

.product-description .product-cart-btn:hover, .product-description .product-detail-btn:hover {
  color: #2B3990;
}

.product-thumb label {
  background: #2B3990;
}

.ui-slider-horizontal .ui-slider-range {
  background: #2B3990;
}

.top-products .top-products-detail i {
  color: #2B3990;
}

.top-products .top-products-detail h4 a:hover {
  color: #2B3990;
}

.ratings i {
  color: #2B3990;
}

.resp-vtabs li.resp-tab-active {
  color: #2B3990;
}

.resp-tab-active {
  background: #2B3990;
}

.cart-total .amount {
  color: #2B3990;
}

.banner-btn.colored {
  background: #2B3990;
}

.map-with-address-widget a:hover {
  color: #2B3990;
}

blockquote {
  border-color: #2B3990;
}

.cases-filter-nav li a:hover, .cases-filter-nav li a.selected {
  background: #2B3990;
}

.product-detail-slider .rslides_tabs li.rslides_here a {
  background: #2B3990;
}

.review-form-close:hover {
  background: #2B3990;
}

.btn.btn-dark:hover {
  background: #2B3990;
}

.btn-primary:hover {
  background-color: #1f2969;
}

a:hover, a:focus, .btn-link:hover, .btn-link:focus {
  color: #1f2969;
}

.icon-img-1 {
  background-image: url(../images/color-scheme/green/icon1.png);
}

.icon-img-2 {
  background-image: url(../images/color-scheme/green/icon2.png);
}

.icon-img-3 {
  background-image: url(../images/color-scheme/green/icon3.png);
}

.icon-img-4 {
  background-image: url(../images/color-scheme/green/icon4.png);
}

.icon-img-5 {
  background-image: url(../images/color-scheme/green/icon5.png);
}

.icon-img-6 {
  background-image: url(../images/color-scheme/green/icon6.png);
}

.icon-img-7 {
  background-image: url(../images/color-scheme/green/icon7.png);
}

.icon-img-8 {
  background-image: url(../images/color-scheme/green/icon8.png);
}

.icon-img-9 {
  background-image: url(../images/color-scheme/green/icon9.png);
}

#footer .usefull-links-widget li a:hover {
  background-image: url(../images/color-scheme/green/dot-h.png);
}

aside .testimonial-content {
  background-image: url(../images/color-scheme/green/testimonial-pointer2.png);
}

.highlighted-sec li:nth-child(1n+1) .text-box {
  background: #2B3990;
}

.highlighted-sec li:nth-child(2n+2) .text-box {
  background: #3749b7;
}

.highlighted-sec li:nth-child(3n+3) .text-box {
  background: #3749b7;
}

.highlighted-sec li:nth-child(4n+4) .text-box {
  background: #3749b7;
}

.highlighted-sec li:nth-child(5n+5) .text-box {
  background: #3749b7;
}

.highlighted-sec li:nth-child(6n+6) .text-box {
  background: #3749b7;
}

.services.highlighted .owl-item:nth-child(3n+1) .service-box {
  background: #2B3990;
}

.services.highlighted .owl-item:nth-child(3n+2) .service-box {
  background: #25317c;
}

.services.highlighted .owl-item:nth-child(3n+3) .service-box {
  background: #25317c;
}

.cases-item figcaption {
  background: rgba(43, 57, 144, 0.8);
}

#header .top-bar:after, #header .top-bar {
  background: #2B3990;
}

#header .top-bar:before, #header .top-bar p, #header .top-bar p:after {
  background: #dc121a;
}

#header.header-two .main-nav {
  background: #EC1C24;
}

.smallHeader.active #header.header-two {
  background: #EC1C24 !important;
}

.testimonial-2 .testimonials-author p {
  color: #2B3990;
}

#header.header-three .main-nav li a:hover, #header.header-three .main-nav li.active > a {
  color: #2B3990;
}

.doing-the-right-text span {
  color: #EC1C24;
}

/*# sourceMappingURL=color-default.css.map */
